// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs

import { designNameToClassName } from '@amedia/brick-classnames';

import * as theme from '../../build/style-dictionary/alfa/index.js';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

const alfaConfig = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: {
    ...theme.fontSizes,
    titleCqiBase: '1.063rem',
    titleFeatureCqiBase: '1.063rem',
    titleOpinionCqiBase: '1rem',
  },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '4cqi',
    titleFeatureCqi: '4.2cqi',
    titleOpinionCqi: '2.5cqi',
    titleBreakingCqi: '5cqi',
    titleCommercialCqi: '3cqi',
    letterCountSCqi: '3.8cqi',
    letterCountMCqi: '1.8cqi',
    letterCountOpinionSCqi: '3cqi',
    letterCountOpinionMCqi: '0.7cqi',
    letterCountFeatureSCqi: '3cqi',
    letterCountFeatureMCqi: '1.6cqi',
    letterCountBreakingSCqi: '1.3cqi',
    letterCountBreakingMCqi: '1.6cqi',
    letterCountCommercialSCqi: '2.2cqi',
    letterCountCommercialMCqi: '0.7cqi',

    letterCountSingleWordSCqi: '1.25cqi',
    letterCountSingleWordMCqi: '1.8cqi',
  },
  other: theme.other,
};

export const alfaTheme = createTheme(
  designNameToClassName('alfa'),
  createCustomTheme(alfaConfig)
);
