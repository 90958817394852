import type { ThemeTokens } from '@stitches/core/types/stitches';

import { altTheme } from './alt';
import { nettavisenTheme } from './nettavisen';
import { alfaTheme } from './alfa';
import { bravoTheme } from './bravo';
import { charlieTheme } from './charlie';
import { simpleTheme } from './simple';

import type { Design } from '@amedia/brick-themes';

type Values = unknown;

const themes = {
  alfa: alfaTheme,
  bravo: bravoTheme,
  charlie: charlieTheme,
  nettavisen: nettavisenTheme,
  alt: altTheme,
};

export { themes as themeList } from '@amedia/brick-themes';

export { altTheme, alfaTheme, bravoTheme, charlieTheme, nettavisenTheme };

export const getThemeByDesign = (
  design?: Design
): string & {
  className: string;
  selector: string;
} & ThemeTokens<Values, string> => (design && themes[design]) || simpleTheme;
