// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs

import { designNameToClassName } from '@amedia/brick-classnames';

import * as theme from '../../build/style-dictionary/bravo/index.js';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

export const bravo = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: {
    ...theme.fontSizes,
    titleCqiBase: '0.688rem',
    titleFeatureCqiBase: '0.688rem',
    titleOpinionCqiBase: '0.688rem',
  },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '3.75cqi',
    titleOpinionCqi: '4cqi',
    titleFeatureCqi: '3.75cqi',
    titleBreakingCqi: '4cqi',
    titleCommercialCqi: '4cqi',
    letterCountSCqi: '3.2cqi',
    letterCountMCqi: '1.2cqi',
    letterCountOpinionSCqi: '3cqi',
    letterCountOpinionMCqi: '0.7cqi',
    letterCountFeatureSCqi: '3cqi',
    letterCountFeatureMCqi: '1.4cqi',
    letterCountBreakingSCqi: '1.2cqi',
    letterCountBreakingMCqi: '1.6cqi',
    letterCountCommercialSCqi: '2.2cqi',
    letterCountCommercialMCqi: '0.7cqi',
    letterCountSingleWordSCqi: '1.25cqi',
    letterCountSingleWordMCqi: '1.8cqi',
  },
  other: theme.other,
};

export const bravoTheme = createTheme(
  designNameToClassName('bravo'),
  createCustomTheme(bravo)
);
