// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs
import { designNameToClassName } from '@amedia/brick-classnames';

import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';
import * as theme from '../../build/style-dictionary/nettavisen/index.js';

export const nettavisen = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: {
    ...theme.fontSizes,
    titleCqiBase: '0.625rem',
    titleFeatureCqiBase: '0.875rem',
    titleOpinionCqiBase: '0.875rem',
  },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '5cqi',
    titleFeatureCqi: '4.6cqi',
    titleOpinionCqi: '4.2cqi',
    titleBreakingCqi: '5.2cqi',
    titleCommercialCqi: '6cqi',
    letterCountSCqi: '3.3cqi',
    letterCountMCqi: '1.2cqi',
    letterCountOpinionSCqi: '3cqi',
    letterCountOpinionMCqi: '0.7cqi',
    letterCountFeatureSCqi: '3cqi',
    letterCountFeatureMCqi: '2cqi',
    letterCountBreakingSCqi: '1.3cqi',
    letterCountBreakingMCqi: '1.9cqi',
    letterCountCommercialSCqi: '2.2cqi',
    letterCountCommercialMCqi: '0.7cqi',
    letterCountSingleWordSCqi: '1.25cqi',
    letterCountSingleWordMCqi: '1.8cqi',
  },
  other: theme.other,
};

export const nettavisenTheme = createTheme(
  designNameToClassName('nettavisen'),
  createCustomTheme(nettavisen)
);
