export const fonts = {
baseTitlepieceM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseTitlepieceL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseBoostS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseBoostM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseIngressM : "\"National2\", Helvetica, Arial, sans-serif",
baseIngressL : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineXxs : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineXs : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineS : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineM : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineL : "\"National2\", Helvetica, Arial, sans-serif",
baseSubheadS : "\"National2\", Helvetica, Arial, sans-serif",
baseSubheadM : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyS : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyM : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionS : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionM : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaXs : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaS : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaM : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaL : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityXs : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityS : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularS : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularM : "\"National2\", Helvetica, Arial, sans-serif",
storyHeadlineM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
opinionTitlepieceM : "Open Sans",
opinionTitlepieceL : "Open Sans",
featureTitlepieceM : "Playfair Display",
featureTitlepieceL : "Playfair Display",
featureIngressM : "\"National2\", Helvetica, Arial, sans-serif",
featureIngressL : "\"National2\", Helvetica, Arial, sans-serif",
titleReelsMarkerM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
valgomatHeadline1M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXs : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailS : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXl : "\"National2\", Helvetica, Arial, sans-serif",
valgomatUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
pillM : "\"National2\", Helvetica, Arial, sans-serif",
pillBoldM : "\"National2\", Helvetica, Arial, sans-serif",
pillL : "\"National2\", Helvetica, Arial, sans-serif",
pillBoldL : "\"National2\", Helvetica, Arial, sans-serif",
titleXs : "\"National2\", Helvetica, Arial, sans-serif",
titleS : "\"National2\", Helvetica, Arial, sans-serif",
titleM : "\"National2\", Helvetica, Arial, sans-serif",
titleL : "\"National2\", Helvetica, Arial, sans-serif",
titleXl : "\"National2\", Helvetica, Arial, sans-serif",
titleXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingXs : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingS : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingM : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingL : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingXl : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkXs : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkS : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkM : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkL : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkXl : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingDarkXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleOpinionXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureXs : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureS : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureM : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureL : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureXl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXs : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkS : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkM : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkL : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXxl : "\"National2\", Helvetica, Arial, sans-serif",
pretitleXs : "\"National2\", Helvetica, Arial, sans-serif",
pretitleS : "\"National2\", Helvetica, Arial, sans-serif",
pretitleM : "\"National2\", Helvetica, Arial, sans-serif",
pretitleL : "\"National2\", Helvetica, Arial, sans-serif",
pretitleXl : "\"National2\", Helvetica, Arial, sans-serif",
pretitleXxl : "\"National2\", Helvetica, Arial, sans-serif",
tipusM : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleM : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleL : "\"National2\", Helvetica, Arial, sans-serif",
titleCommercialXs : "Merriweather, Georgia, \"Times New Roman\", serif",
titleCommercialXxl : "Merriweather, Georgia, \"Times New Roman\", serif",
avatarMetaS : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaM : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaL : "\"National2\", Helvetica, Arial, sans-serif",
adLabelM : "\"National2\", Helvetica, Arial, sans-serif",
toggleUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
galleryTitlepieceM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
galleryTitlepieceL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
galleryIngressM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
galleryIngressL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
}

export const fontSizes = {
baseTitlepieceM : "2rem",
baseTitlepieceL : "4.375rem",
baseBoostS : "1.3125rem",
baseBoostM : "3.125rem",
baseIngressM : "1.4375rem",
baseIngressL : "2rem",
baseHeadlineXxs : "0.875rem",
baseHeadlineXs : "1rem",
baseHeadlineS : "1.125rem",
baseHeadlineM : "1.5rem",
baseHeadlineL : "1.75rem",
baseSubheadS : "1.0625rem",
baseSubheadM : "1.1875rem",
baseBodyS : "1.0625rem",
baseBodyM : "1.1875rem",
baseCaptionS : "0.9375rem",
baseCaptionM : "1.0625rem",
baseMetaXs : "0.6875rem",
baseMetaS : "0.8125rem",
baseMetaM : "0.9375rem",
baseMetaL : "1.0625rem",
baseUtilityXs : "0.9375rem",
baseUtilityS : "1.0625rem",
baseUtilityM : "1.125rem",
baseTabularS : "1.0625rem",
baseTabularM : "1.1875rem",
storyHeadlineM : "1.5rem",
opinionTitlepieceM : "2rem",
opinionTitlepieceL : "4.375rem",
featureTitlepieceM : "1.625rem",
featureTitlepieceL : "4.125rem",
featureIngressM : "1.625rem",
featureIngressL : "4.375rem",
titleReelsMarkerM : "1.3125rem",
valgomatHeadline1M : "2rem",
valgomatHeadline1L : "2.25rem",
valgomatHeadline2M : "1.25rem",
valgomatHeadline2L : "1.5rem",
valgomatQuestionM : "1.125rem",
valgomatQuestionL : "1.5rem",
valgomatDetailXs : "0.875rem",
valgomatDetailS : "1rem",
valgomatDetailM : "1.25rem",
valgomatDetailL : "1.5rem",
valgomatDetailXl : "2.25rem",
valgomatUtilityM : "1.125rem",
pillM : "0.8125rem",
pillBoldM : "0.8125rem",
pillL : "0.875rem",
pillBoldL : "0.875rem",
titleXs : "1.1875rem",
titleS : "1.3125rem",
titleM : "1.625rem",
titleL : "1.875rem",
titleXl : "2.5rem",
titleXxl : "3.125rem",
titleBreakingXs : "1.3125rem",
titleBreakingS : "1.3125rem",
titleBreakingM : "1.875rem",
titleBreakingL : "2.1875rem",
titleBreakingXl : "2.5rem",
titleBreakingXxl : "3.125rem",
titleBreakingDarkXs : "1.3125rem",
titleBreakingDarkS : "1.3125rem",
titleBreakingDarkM : "1.875rem",
titleBreakingDarkL : "2.5rem",
titleBreakingDarkXl : "3.125rem",
titleBreakingDarkXxl : "3.125rem",
titleOpinionXs : "1.625rem",
titleOpinionS : "1.6875rem",
titleOpinionM : "2.1875rem",
titleOpinionL : "2.1875rem",
titleOpinionXl : "2.8125rem",
titleOpinionXxl : "3.125rem",
titleFeatureXs : "1.3125rem",
titleFeatureS : "1.3125rem",
titleFeatureM : "1.875rem",
titleFeatureL : "2.1875rem",
titleFeatureXl : "3.4375rem",
titleFeatureXxl : "3.75rem",
titleFeatureDarkXs : "1.3125rem",
titleFeatureDarkS : "1.3125rem",
titleFeatureDarkM : "1.875rem",
titleFeatureDarkL : "2.5rem",
titleFeatureDarkXl : "3.125rem",
titleFeatureDarkXxl : "3.75rem",
pretitleXs : "1rem",
pretitleS : "1rem",
pretitleM : "1rem",
pretitleL : "1.25rem",
pretitleXl : "1.25rem",
pretitleXxl : "1.25rem",
tipusM : "1.125rem",
groupTitleM : "1.0625rem",
groupTitleL : "1.625rem",
titleCommercialXs : "1.375rem",
titleCommercialXxl : "2.375rem",
avatarMetaS : "0.8125rem",
avatarMetaM : "0.9375rem",
avatarMetaL : "1.125rem",
adLabelM : "0.8125rem",
toggleUtilityM : "1.125rem",
galleryTitlepieceM : "2rem",
galleryTitlepieceL : "4.375rem",
galleryIngressM : "2rem",
galleryIngressL : "4.375rem",
}

export const fontWeights = {
baseTitlepieceM : 800,
baseTitlepieceL : 800,
baseBoostS : 800,
baseBoostM : 800,
baseIngressM : 400,
baseIngressL : 400,
baseHeadlineXxs : 500,
baseHeadlineXs : 500,
baseHeadlineS : 500,
baseHeadlineM : 500,
baseHeadlineL : 500,
baseSubheadS : 500,
baseSubheadM : 500,
baseBodyS : 400,
baseBodyM : 400,
baseCaptionS : 400,
baseCaptionM : 400,
baseMetaXs : 400,
baseMetaS : 400,
baseMetaM : 400,
baseMetaL : 400,
baseUtilityXs : 400,
baseUtilityS : 400,
baseUtilityM : 400,
baseTabularS : 400,
baseTabularM : 400,
storyHeadlineM : 700,
opinionTitlepieceM : 700,
opinionTitlepieceL : 700,
featureTitlepieceM : 700,
featureTitlepieceL : 700,
featureIngressM : 300,
featureIngressL : 300,
titleReelsMarkerM : 700,
valgomatHeadline1M : 400,
valgomatHeadline1L : 400,
valgomatHeadline2M : 400,
valgomatHeadline2L : 400,
valgomatQuestionM : 400,
valgomatQuestionL : 400,
valgomatDetailXs : 400,
valgomatDetailS : 400,
valgomatDetailM : 400,
valgomatDetailL : 400,
valgomatDetailXl : 500,
valgomatUtilityM : 400,
pillM : 400,
pillBoldM : 500,
pillL : 400,
pillBoldL : 500,
titleXs : 500,
titleS : 500,
titleM : 500,
titleL : 500,
titleXl : 500,
titleXxl : 500,
titleBreakingXs : 500,
titleBreakingS : 500,
titleBreakingM : 500,
titleBreakingL : 500,
titleBreakingXl : 500,
titleBreakingXxl : 500,
titleBreakingDarkXs : 700,
titleBreakingDarkS : 700,
titleBreakingDarkM : 700,
titleBreakingDarkL : 700,
titleBreakingDarkXl : 700,
titleBreakingDarkXxl : 700,
titleOpinionXs : 400,
titleOpinionS : 400,
titleOpinionM : 400,
titleOpinionL : 400,
titleOpinionXl : 400,
titleOpinionXxl : 400,
titleFeatureXs : 700,
titleFeatureS : 700,
titleFeatureM : 700,
titleFeatureL : 700,
titleFeatureXl : 700,
titleFeatureXxl : 700,
titleFeatureDarkXs : 700,
titleFeatureDarkS : 700,
titleFeatureDarkM : 700,
titleFeatureDarkL : 700,
titleFeatureDarkXl : 700,
titleFeatureDarkXxl : 700,
pretitleXs : 400,
pretitleS : 400,
pretitleM : 400,
pretitleL : 400,
pretitleXl : 400,
pretitleXxl : 400,
tipusM : 400,
groupTitleM : 500,
groupTitleL : 500,
titleCommercialXs : 700,
titleCommercialXxl : 700,
avatarMetaS : 500,
avatarMetaM : 500,
avatarMetaL : 500,
adLabelM : 500,
toggleUtilityM : 400,
galleryTitlepieceM : 800,
galleryTitlepieceL : 800,
galleryIngressM : 800,
galleryIngressL : 800,
}

export const letterSpacings ={
baseTitlepieceM : "0rem",
baseTitlepieceL : "0rem",
baseBoostS : "-0.0099626412551359rem",
baseBoostM : "-0.0099626412551359rem",
baseIngressM : "0rem",
baseIngressL : "0rem",
baseHeadlineXxs : "-0.0099626412551359rem",
baseHeadlineXs : "-0.0099626412551359rem",
baseHeadlineS : "-0.0099626412551359rem",
baseHeadlineM : "-0.0199252825102718rem",
baseHeadlineL : "-0.0199252825102718rem",
baseSubheadS : "-0.0099626412551359rem",
baseSubheadM : "-0.0099626412551359rem",
baseBodyS : "normal",
baseBodyM : "normal",
baseCaptionS : "normal",
baseCaptionM : "normal",
baseMetaXs : "normal",
baseMetaS : "normal",
baseMetaM : "normal",
baseMetaL : "normal",
baseUtilityXs : "-0.0099626412551359rem",
baseUtilityS : "-0.0099626412551359rem",
baseUtilityM : "-0.0099626412551359rem",
baseTabularS : "normal",
baseTabularM : "normal",
storyHeadlineM : "0rem",
opinionTitlepieceM : "-0.0099626412551359rem",
opinionTitlepieceL : "-0.0099626412551359rem",
featureTitlepieceM : "0rem",
featureTitlepieceL : "0rem",
featureIngressM : "0rem",
featureIngressL : "0rem",
titleReelsMarkerM : "-0.0099626412551359rem",
valgomatHeadline1M : "0rem",
valgomatHeadline1L : "0rem",
valgomatHeadline2M : "",
valgomatHeadline2L : "",
valgomatQuestionM : "",
valgomatQuestionL : "",
valgomatDetailXs : "",
valgomatDetailS : "",
valgomatDetailM : "",
valgomatDetailL : "",
valgomatDetailXl : "",
pillM : "normal",
pillBoldM : "normal",
pillBoldL : "normal",
titleXs : "-0.0099626412551359rem",
titleS : "-0.0199252825102718rem",
titleM : "-0.0199252825102718rem",
titleL : "-0.0199252825102718rem",
titleXl : "-0.0199252825102718rem",
titleXxl : "-0.0199252825102718rem",
titleBreakingXs : "-0.0099626412551359rem",
titleBreakingS : "-0.0099626412551359rem",
titleBreakingM : "-0.0199252825102718rem",
titleBreakingL : "-0.0199252825102718rem",
titleBreakingXl : "-0.0199252825102718rem",
titleBreakingXxl : "-0.0199252825102718rem",
titleBreakingDarkXs : "-0.0099626412551359rem",
titleBreakingDarkS : "-0.0099626412551359rem",
titleBreakingDarkM : "-0.0199252825102718rem",
titleBreakingDarkL : "-0.0199252825102718rem",
titleBreakingDarkXl : "-0.0199252825102718rem",
titleBreakingDarkXxl : "-0.0199252825102718rem",
titleOpinionXs : "-0.0199252825102718rem",
titleOpinionS : "-0.0199252825102718rem",
titleOpinionM : "-0.0199252825102718rem",
titleOpinionL : "-0.0199252825102718rem",
titleOpinionXl : "-0.0199252825102718rem",
titleOpinionXxl : "-0.0199252825102718rem",
titleFeatureXs : "-0.0099626412551359rem",
titleFeatureS : "-0.0099626412551359rem",
titleFeatureM : "-0.0199252825102718rem",
titleFeatureL : "-0.0199252825102718rem",
titleFeatureXl : "-0.0199252825102718rem",
titleFeatureXxl : "-0.0199252825102718rem",
titleFeatureDarkXs : "-0.0099626412551359rem",
titleFeatureDarkS : "-0.0099626412551359rem",
titleFeatureDarkM : "-0.0199252825102718rem",
titleFeatureDarkL : "-0.0199252825102718rem",
titleFeatureDarkXl : "-0.0199252825102718rem",
titleFeatureDarkXxl : "-0.0199252825102718rem",
pretitleXs : "-0.0099626412551359rem",
pretitleS : "-0.0099626412551359rem",
pretitleM : "-0.0099626412551359rem",
pretitleL : "-0.0099626412551359rem",
pretitleXl : "-0.0099626412551359rem",
pretitleXxl : "-0.0099626412551359rem",
tipusM : "-0.0099626412551359rem",
groupTitleM : "",
groupTitleL : "-0.0099626412551359rem",
titleCommercialXs : "-0.0099626412551359rem",
titleCommercialXxl : "-0.0099626412551359rem",
avatarMetaS : "",
avatarMetaM : "",
avatarMetaL : "",
adLabelM : "0.049813206275679506rem",
toggleUtilityM : "-0.0099626412551359rem",
galleryTitlepieceM : "0rem",
galleryTitlepieceL : "0rem",
galleryIngressM : "0rem",
galleryIngressL : "0rem",
}

export const lineHeights = {
baseTitlepieceM : "1.05",
baseTitlepieceL : "1.05",
baseBoostS : "1.05",
baseBoostM : "1.05",
baseIngressM : "1.15",
baseIngressL : "1.15",
baseHeadlineXxs : "1.2",
baseHeadlineXs : "1.2",
baseHeadlineS : "1.2",
baseHeadlineM : "1.2",
baseHeadlineL : "1.2",
baseSubheadS : "1.2",
baseSubheadM : "1.2",
baseBodyS : "1.6",
baseBodyM : "1.5",
baseCaptionS : "1.4",
baseCaptionM : "1.4",
baseMetaXs : "normal",
baseMetaS : "normal",
baseMetaM : "normal",
baseMetaL : "normal",
baseUtilityXs : "1.5",
baseUtilityS : "1.2",
baseUtilityM : "1.2",
baseTabularS : "normal",
baseTabularM : "normal",
storyHeadlineM : "1.2",
opinionTitlepieceM : "1.05",
opinionTitlepieceL : "1.05",
featureTitlepieceM : "1.1",
featureTitlepieceL : "1.1",
featureIngressM : "1.3",
featureIngressL : "1.3",
titleReelsMarkerM : "1.25",
valgomatHeadline1M : "1.25",
valgomatHeadline1L : "1.25",
valgomatHeadline2M : "1.5",
valgomatHeadline2L : "1.5",
valgomatQuestionM : "1.5",
valgomatQuestionL : "1.5",
valgomatDetailXs : "1.5",
valgomatDetailS : "1.5",
valgomatDetailM : "1.5",
valgomatDetailL : "1.5",
valgomatDetailXl : "1.5",
valgomatUtilityM : "1.5",
pillM : "1.2",
pillBoldM : "1.2",
pillL : "1.2",
pillBoldL : "1.2",
titleXs : "1.05",
titleS : "1.05",
titleM : "1.05",
titleL : "1.05",
titleXl : "1.05",
titleXxl : "1.05",
titleBreakingXs : "1.05",
titleBreakingS : "1.05",
titleBreakingM : "1.05",
titleBreakingL : "1.05",
titleBreakingXl : "1.05",
titleBreakingXxl : "1.05",
titleBreakingDarkXs : "1.05",
titleBreakingDarkS : "1.05",
titleBreakingDarkM : "1.05",
titleBreakingDarkL : "1.05",
titleBreakingDarkXl : "1.05",
titleBreakingDarkXxl : "1.05",
titleOpinionXs : "1.05",
titleOpinionS : "1",
titleOpinionM : "1.05",
titleOpinionL : "1.05",
titleOpinionXl : "1.05",
titleOpinionXxl : "1.05",
titleFeatureXs : "1.05",
titleFeatureS : "1.05",
titleFeatureM : "1.05",
titleFeatureL : "1.05",
titleFeatureXl : "1.05",
titleFeatureXxl : "1.05",
titleFeatureDarkXs : "1.05",
titleFeatureDarkS : "1.05",
titleFeatureDarkM : "1.05",
titleFeatureDarkL : "1.05",
titleFeatureDarkXl : "1.05",
titleFeatureDarkXxl : "1.05",
pretitleXs : "1.1",
pretitleS : "1.1",
pretitleM : "1.1",
pretitleL : "1.05",
pretitleXl : "1.05",
pretitleXxl : "1.05",
tipusM : "1.1",
groupTitleM : "1.05",
groupTitleL : "1.05",
titleCommercialXs : "1.1",
titleCommercialXxl : "1.1",
avatarMetaS : "1.05",
avatarMetaM : "1.05",
avatarMetaL : "1.05",
toggleUtilityM : "1.2",
galleryTitlepieceM : "1.05",
galleryTitlepieceL : "1.05",
galleryIngressM : "1.05",
galleryIngressL : "1.05",
}
