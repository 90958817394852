/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const premiumIconM = "0.8125rem";
export const premiumIconL = "1.25rem";
export const iconS = "0.6875rem";
export const iconM = "1.5rem";
export const avatarS = "2.5rem";
export const avatarM = "3.125rem";
export const avatarL = "4.375rem";
